<edi-animated-form-container>

  <div class={ expanded: isOpen, container: true } ref="container">
    <div hide={ !isOpen } ref="innerContainer">
      <yield />
    </div>
  </div>

  <script>
    import './animated-form-container.css'
    import {
      getContainerHeight,
      expandContainer,
      collapseContainer
    } from '../../common/utils/dom'
    import { debounce } from '../../common/utils/debounce'

    const setContainerHeightOnResize = () => {
      this.containerHeight = getContainerHeight(this.container)
      this.isOpen
        ? this.container.style.height = 'auto'
        : this.container.style.height = null
    }

    this.on('before-mount', () => {
      this.isOpen = this.opts.isOpen
    })

    const init = () => {
      this.container = this.refs.container

      this.containerHeight = getContainerHeight(this.container)

      this.isOpen
        ? expandContainer(this.container, this.containerHeight)
        : collapseContainer(this.container)

      window.addEventListener('resize', debounce(setContainerHeightOnResize, 100))
    }

    this.on('mount', () => {
      const observer = new MutationObserver(() => this.update())

      observer.observe(this.refs.innerContainer, {
        attributes: true,
        attributeFilter: ['style'],
        attributeOldValue: true
      })

      const tags = Object.values(this.tags)

      if (tags.length > 0) {
        //Currently only supports one tag yielded in
        //Wait for yielded tag to mount before getting height..
        let yieldedTag = tags[0]
        if (Array.isArray(yieldedTag))
          yieldedTag = yieldedTag[0]

        yieldedTag.on('mount', () => {
          init()
        })
      } else {
        init()
      }
    })

    this.on('update', () => {
      this.isOpen = this.opts.isOpen

      if (!this.containerHeight) {
        this.containerHeight = getContainerHeight(this.container)
      }

      if (this.isOpen) {
        expandContainer(this.container, this.containerHeight)
      } else {
        collapseContainer(this.container)
      }
    })

  </script>

</edi-animated-form-container>
