/*
  http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
*/
const isElementInViewport = el => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /*or $(window).height() */ &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
  );
};

export const focusInvalidElement = () => {
  setTimeout(() => {
    // Wait for riot to update the dom
    const firstErrorElement = document.body.querySelector(
      '.has-error input, .has-error select, .focus-error-marker select',
    );

    if (firstErrorElement) {
      if (firstErrorElement.tagName === 'INPUT' || firstErrorElement.tagName === 'SELECT') {
        firstErrorElement.focus();
      }
      if (!isElementInViewport(firstErrorElement)) {
        firstErrorElement.scrollIntoView();
      }
    }
  }, 100);
};

export const getContainerHeight = container => {
  container.style.height = 'auto';

  const { height } = container.getBoundingClientRect();

  container.style.height = null;
  container.offsetHeight;

  return height;
};

export const expandContainer = (container, height) => {
  container.style.height = `${height}px`;
};

export const collapseContainer = container => {
  container.style.height = null;
};
