import { createStore, applyMiddleware, compose } from 'redux';
import effects from 'redux-effects';

import fetch, { fetchEncodeJSON } from './effects/redux-effects-fetch';
import { auth } from './effects/auth';
import { validationEffects } from './effects/validation';

import { navigator } from './navigation';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'EDI', serialize: true })
  : compose;

export const configureStore = (preLoadedState, reducers) =>
  createStore(
    reducers,
    preLoadedState,
    composeEnhancers(
      applyMiddleware(auth(window.sessionStorage), effects, fetchEncodeJSON, fetch, navigator, validationEffects),
    ),
  );
