<edi-co-holder>

  <edi-unified-noval-text-input
    model={ firstNameState }
    handle-change={ onFirstNameInput } />

  <edi-unified-noval-text-input
    model={ lastNameState }
    handle-change={ onLastNameInput } />

  <edi-unified-noval-text-input
    model={ legalNumberState }
    handle-change={ onLegalNumberInput } />

  <edi-unified-noval-text-input
    overridden-error-styling
    model={ phoneNumberState }
    handle-change={ onPhoneNumberInput }
    style="height: 136px;" />

  <script>
    import {
      getCoHolderFirstNameState,
      getCoHolderLastNameState,
      getCoHolderLegalNumberState,
      getCoHolderPhoneNumberState,
    } from '../../common/selectors'
    import {
      setCoHolderFirstNameAction,
      validateCoHolderFirstNameAction,
      setCoHolderLastNameAction,
      validateCoHolderLastNameAction,
      setCoHolderLegalNumberAction,
      validateCoHolderLegalNumberAction,
      setCoHolderPhoneNumberAction,
      validateCoHolderPhoneNumberAction,
    } from '../../common/actions'
    import './co-holder.css'

    const legalNumberOptions = {
      businessArea: 'private',
      legalUnit: 'DK',
      errorKey: 'invalidLegalNumber',
      name: 'legalNumber'
    }

    const phoneNumberOptions = () => ({
      businessArea: 'private',
      legalUnit: null
    })

    this.on('before-mount', () => {
      this.subscribe(getCoHolderFirstNameState, firstNameState =>
        this.update({ firstNameState }))

      this.subscribe(getCoHolderLastNameState, lastNameState =>
        this.update({ lastNameState }))

      this.subscribe(getCoHolderLegalNumberState, legalNumberState =>
        this.update({ legalNumberState }))

      this.subscribe(getCoHolderPhoneNumberState, phoneNumberState =>
        this.update({ phoneNumberState }))
    })

    this.onFirstNameInput = ({ value }) => {
      if (value !== this.firstNameState.value) {
        this.dispatch(setCoHolderFirstNameAction(value))
        this.dispatch(validateCoHolderFirstNameAction(value))
      }
    }

    this.onLastNameInput = ({ value }) => {
      if (value !== this.lastNameState.value) {
        this.dispatch(setCoHolderLastNameAction(value))
        this.dispatch(validateCoHolderLastNameAction(value))
      }
    }

    this.onLegalNumberInput = ({ value }) => {
      if (value !== this.legalNumberState.value) {
        this.dispatch(setCoHolderLegalNumberAction(value, legalNumberOptions))
        this.dispatch(validateCoHolderLegalNumberAction(value, legalNumberOptions))
      }
    }

    this.onPhoneNumberInput = ({ value }) => {
      if (value !== this.phoneNumberState.value) {
        this.dispatch(setCoHolderPhoneNumberAction(value))
        this.dispatch(validateCoHolderPhoneNumberAction(value, phoneNumberOptions))
      }
    }

  </script>

</edi-co-holder>
