import { types, territories, INTERNATIONAL_PREFIX } from './phone-validator.metadata';

const calculatePlausability = (value, country, pattern) => {
  const tmp = { type: pattern.type, score: 0, rawInput: value, isValid: false };

  const UNSUPPORTED_CHARS = /[^\d^\s\-+]+/g;
  const ONLY_DIGITS = /\d+/g;

  if (value.match(UNSUPPORTED_CHARS)) return tmp;

  let digits = value.match(ONLY_DIGITS) ? value.match(ONLY_DIGITS).join('') : '0';
  const hasNationalCode = digits.startsWith(country.countryCode);
  const hasInternationalPrefix = INTERNATIONAL_PREFIX.some(prefix => prefix === value.substring(0, 1));
  const exceedsPatternLengthLimit = digits.length > (pattern.maxLength || pattern.length);

  if (hasInternationalPrefix && !hasNationalCode) return tmp;

  if (exceedsPatternLengthLimit && hasNationalCode) {
    digits = digits.replace(new RegExp(`^(${country.countryCode})`), '');
  }

  if (typeof country.nationalPrefix !== 'undefined') {
    const prefix = new RegExp(country.nationalPrefix);

    if (prefix.test(digits)) {
      digits = digits.replace(prefix, '');
    }
  }

  const patternMatch = digits.match(pattern.pattern);

  if (!patternMatch) return tmp;

  const numberMatch = patternMatch[0] || null;

  if (!numberMatch) return tmp;

  let matchedByLength = false;
  let score = 0;

  if (pattern.minLength && pattern.maxLength) {
    matchedByLength = numberMatch.length >= pattern.minLength && numberMatch.length <= pattern.maxLength;
  } else if (pattern.length) {
    matchedByLength = numberMatch.length === pattern.length;
  } else {
    matchedByLength = false;
  }

  if (matchedByLength) {
    if (numberMatch.length > 0) score++;
    if (hasNationalCode) score++;
  }

  return {
    type: pattern.type,
    score: score,
    nationalNumber: digits,
    rawInput: value,
  };
};

export const parse = (value, groupResult = true, country = null, type = null) => {
  const res = [];

  if (!value) return res;

  const countries = country ? territories.filter(c => c.countryId === country) : territories;

  countries.forEach(country => {
    const patterns = type ? country.patterns.filter(p => p.type === type) : country.patterns;

    patterns
      .map(pattern => calculatePlausability(value, country, pattern))
      .filter(match => match.score > 0)
      .sort((a, b) => (a.score > b.score ? -1 : 1))
      .forEach(match => {
        res.push({
          score: match.score,
          country: country.countryId,
          type: match.type,
          nationalNumber: match.nationalNumber,
          rawInput: value,
          isValid: true,
        });
      });
  });

  let groupByCountry = res;

  if (groupResult) {
    groupByCountry = res.reduce((acc, curr) => {
      if (!acc) return [curr];

      const existing = acc.find(m => m.country === curr.country);

      if (existing) {
        if (
          (existing.type === types.MOBILE && curr.type === types.FIXED_LINE) ||
          (existing.type === types.FIXED_LINE && curr.type === types.MOBILE)
        ) {
          existing.type = types.FIXED_LINE_OR_MOBILE;
        }
      } else {
        acc = [...acc, curr];
      }

      return acc;
    }, []);

    if (!groupByCountry) return [];
  }

  const limit = groupByCountry.reduce((acc, curr) => Math.max(acc, curr.score), 0);

  return groupByCountry
    .filter(m => m.score === limit)
    .map(m => ({
      country: m.country,
      type: m.type,
      nationalNumber: m.nationalNumber,
      rawInput: m.rawInput,
      isValid: m.isValid,
    }));
};

const isValid = (value, country = null, type = null) => {
  const hasCountry = country !== null;
  const hasType = type !== null;
  const group = hasType ? false : true;
  const res = parse(value, group, country, type);

  if (res.length === 0) return false;
  if (!hasCountry && !hasType) return res[0].isValid;
  if (hasCountry && !hasType) return res.filter(r => r.country === country).length > 0;
  if (hasType && !hasCountry) return res.filter(r => r.type === type).length > 0;

  return res.filter(r => r.country === country && r.type === type).length > 0;
};

const formatNumber = parsedNumber => {
  const tmp = {
    national: parsedNumber.nationalNumber,
    international: parsedNumber.nationalNumber,
    e164: parsedNumber.nationalNumber,
  };

  if (!parsedNumber || !parsedNumber.nationalNumber) return tmp;

  const country = territories.find(t => t.countryId === parsedNumber.country);

  if (!country || !country.formatting) return tmp;

  const fmt = country.formatting.find(
    f =>
      (f.leadingDigits ? f.leadingDigits.test(parsedNumber.nationalNumber) : true) &&
      f.pattern.test(parsedNumber.nationalNumber),
  );

  if (!fmt) return tmp;

  return {
    national: parsedNumber.nationalNumber.replace(fmt.pattern, fmt.national),
    international: parsedNumber.nationalNumber.replace(fmt.pattern, fmt.international),
    e164: parsedNumber.nationalNumber.replace(fmt.pattern, fmt.e164),
  };
};

const parseFormat = (payload, country = null) => {
  const parsed = parse(payload, false, country);

  if (parsed.length === 0 || !parsed[0].isValid) return { national: payload, international: payload, e164: payload };

  return formatNumber(parsed[0]);
};

export const phoneValidator = {
  parseFormat,
  isValid,
  formatNumber,
  parse,
};
