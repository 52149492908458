import { Types } from '../actions';
import { initialState } from '../state/co-holder.state';

export const coHolder = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_CO_HOLDER_INPUT:
      return {
        ...state,
        [action.meta.name]: {
          ...state[action.meta.name],
          value: action.payload,
          validated: false,
        },
      };
    case Types.VALIDATE_CO_HOLDER_INPUT_SUCCESS:
      return {
        ...state,
        [action.meta.name]: {
          ...state[action.meta.name],
          validated: true,
          error: null,
        },
      };
    case Types.VALIDATE_CO_HOLDER_INPUT_FAIL:
      return {
        ...state,
        [action.meta.name]: {
          ...state[action.meta.name],
          validated: true,
          error: action.payload,
        },
      };
    case Types.FETCH_INITIALIZE_SUCCESS:
      const ediData = action.payload.ediData || {};
      return {
        ...state,
        firstName: {
          ...state.firstName,
          value: ediData.coHolderFirstName || '',
        },
        lastName: {
          ...state.lastName,
          value: ediData.coHolderLastName || '',
        },
        legalNumber: {
          ...state.legalNumber,
          value: ediData.coHolderLegalNumber || '',
        },
        phoneNumber: {
          ...state.phoneNumber,
          value: ediData.coHolderPhoneNumber || '',
        },
      };
    default:
      return state;
  }
};
