export interface Maybe<T> {
  isNothing(): boolean;
  map<U>(fn: (value: T) => U): Maybe<U>;
  flatMap<U>(fn: (value: T) => Maybe<U>): Maybe<U>;
  valueOfOrElse(defaultValue: T): T;
  valueOf(): T | null | undefined;
}

export const Maybe = <T extends any>(value: T): Maybe<T> =>
  value === null || typeof value === 'undefined'
    ? Maybe.Nothing()
    : {
        isNothing() {
          return false;
        },
        map(fn) {
          return Maybe(fn(value));
        },
        flatMap(fn) {
          return fn(value);
        },
        valueOfOrElse() {
          return value;
        },
        valueOf() {
          return value;
        },
      };

Maybe.Nothing = (): Maybe<any> => ({
  isNothing: () => true,
  map: () => Maybe.Nothing(),
  flatMap: () => Maybe.Nothing(),
  valueOfOrElse: defaultValue => defaultValue,
  valueOf: () => null,
});
