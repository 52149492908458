import { createSelector } from 'reselect';

export const getStagedPolicyState = state => state.stagedPolicy;

export const getStartingWishState = createSelector(
  getStagedPolicyState,
  state => state.startingWish,
);

export const getStartDateState = createSelector(
  getStagedPolicyState,
  state => state.startDate,
);

export const getStagingSessionId = createSelector(
  getStagedPolicyState,
  state => state.stagingSessionId,
);
export const getStagingSessionIndex = createSelector(
  getStagedPolicyState,
  state => state.stagingSessionIndex,
);

export const getIsFormBusy = createSelector(
  getStagedPolicyState,
  state => state.isBusy === true,
);

export const getIsEdiProcessCompleted = createSelector(
  getStagedPolicyState,
  state => state.isBusy === false && state.completed === true,
);

export const getIsEdiProcessSuccesful = createSelector(
  getStagedPolicyState,
  state => state.successful,
);

export const getFirstPossibleStartDateState = createSelector(
  getStagedPolicyState,
  state => state.firstPossibleStartDateState,
);

export const getIsCommercial = createSelector(
  getStagedPolicyState,
  state => state.isCommercial,
)

export const getIsInternal = createSelector(
  getStagedPolicyState,
  state => state.isInternal,
)
