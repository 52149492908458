export const getDefaultStartDate = () => {
  const now = new Date();

  const YYYY = now.getFullYear()
  const MM = now.getMonth() < 9 ? `0${now.getMonth() + 1}`: now.getMonth() + 1
  const DD = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate()

  return `${YYYY}-${MM}-${DD}`;
}

export const getMinimumStartDate = () => getDefaultStartDate()

export const getEndDate = () => {
  const now = new Date()

  const YYYY = now.getFullYear()
  const MM = now.getMonth() < 9 ? `0${now.getMonth() + 1}`: now.getMonth() + 1
  const DD = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate()

  return `${YYYY + 1}-${MM}-${DD}`;
}
