/**
 * Action types
 */

export const FETCH = 'EFFECT_FETCH';

/**
 * g - Return the global object (in the browser or node)
 */

const g = () => (typeof window === 'undefined' ? global : window);

/**
 * Check the status and reject the promise if it's not in the 200 range
 */

const checkStatus = res => {
  if (res.status >= 200 && res.status < 300) {
    return res;
  }
  throw res;
};

/**
 * Deserialize the request body
 */

const deserialize = res => {
  const header = res.headers.get('Content-Type') || '';
  if (header.indexOf('application/json') > -1) return res.json();
  if (header.indexOf('application/ld+json') > -1) return res.json();
  if (header.indexOf('application/octet-stream') > -1) return res.arrayBuffer();
  return res.text();
};

/**
 * Create a plain JS response object.  Note that 'headers' is still a Headers
 * object (https://developer.mozilla.org/en-US/docs/Web/API/Headers), and must be
 * read using that API.
 */

const createResponse = res =>
  deserialize(res).then(
    value => ({
      url: res.url,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers,
      value: value,
    }),
    err => {
      throw { value: err };
    },
  );

/**
 * Create the response, then return a new rejected
 * promise so the failure chain stays failed.
 */

const createErrorResponse = res => {
  const q = res.headers ? createResponse(res) : Promise.resolve(res);

  return q.then(res => {
    throw res;
  });
};

/**
 * Fetch middleware
 */

export const fetchMiddleware = () => next => action =>
  action.type === FETCH
    ? g()
        .fetch(action.payload.url, action.payload.params)
        .then(checkStatus)
        .then(createResponse, createErrorResponse)
    : next(action);

/**
 * Action creator
 */

export const fetchActionCreator = (url = '', params = {}) => ({
  type: FETCH,
  payload: {
    url,
    params,
  },
});
