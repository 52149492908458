export const auth = storage => () => next => action => {
  if (action.type === 'EFFECT_FETCH') {
    const token = storage.getItem('access_token');

    if (token) {
      action.payload.url.headers.append('Authorization', `Bearer ${token}`);
    }
  }

  return next(action);
};
