import { cancellationDateByExpiry, cancellationDateByWithdrawal, cancellationDateShorterNoticePeriod, noEdi } from '../constants/cancellation-date-codes';

export const privateDefaultOptions = [{ name: 'Så hurtigt som muligt', value: cancellationDateShorterNoticePeriod }, { name: 'Når min gamle forsikring udløber', value: cancellationDateByExpiry }];
export const privateOptionsInternal = [...privateDefaultOptions, { name: 'I dag, EDI fortrydelse sendes', value: cancellationDateByWithdrawal }];
export const privateOptionsInternalWithMotor = [...privateOptionsInternal, { name: 'I dag, modbevis sendes', value: noEdi }];
export const commercialDefaultOptions = [{ name: 'Når min gamle forsikring udløber', value: cancellationDateByExpiry }];
export const commercialOptionsInternalWithMotor = [...commercialDefaultOptions, { name: 'I dag, modbevis sendes', value: noEdi }];

export const initialState = {
  startingWish: {
    title: 'Hvornår skal din forsikring starte?',
    description: 'Angiv hvornår din forsikring skal gælde fra. Dette kan være fra når din nuværende forsikring udløber eller hurtigst muligt.',
    value: cancellationDateShorterNoticePeriod,
    options: undefined,
  },
  startDate: {
    label: 'Hvornår skal din forsikring starte?',
    description: 'Din forsikring kan tidligst starte i dag og senest om 12 måneder.',
    name: 'StartDate',
    value: '',
    disabled: false,
  },
  firstPossibleStartDateState: {
    text: 'Forsikringen gælder fra',
    cancelAsSoonAsPossibleDate: null,
    cancelMonthFirstPossibleDate: null,
  },
  isCommercial: false,
  isInternal: false,
};
