import { bind } from 'redux-effects';
import { legalNumberParser } from './legal-number/parser';
import { isNullOrEmpty } from '../utils';
import {
  requiredValidator,
  numberValidator,
  phoneValidator,
  legalNumberValidator,
  Err,
} from '../validation';

//NOTE: Can be more generic but for keep this way for now for sake of ease

export const Types = {
  SET_CO_HOLDER_INPUT: '[EDI] SET_CO_HOLDER_INPUT',
  VALIDATE_CO_HOLDER_INPUT: '[EDI] VALIDATE_CO_HOLDER_INPUT',
  VALIDATE_CO_HOLDER_INPUT_SUCCESS: '[EDI] VALIDATE_CO_HOLDER_INPUT_SUCCESS',
  VALIDATE_CO_HOLDER_INPUT_FAIL: '[EDI] VALIDATE_CO_HOLDER_INPUT_FAIL',
  FETCH_INITIALIZE_SUCCESS: '[EDI] FETCH_INITIALIZE_SUCCESS',
};

export const fetchInitializeSuccessAction = res => ({
  type: Types.FETCH_INITIALIZE_SUCCESS,
  payload: res.value,
});

export const setCoHolderLegalNumberAction = (value = '', options = {}) => ({
  type: Types.SET_CO_HOLDER_INPUT,
  payload: legalNumberParser(value, options),
  meta: { name: 'legalNumber', ...options },
});

export const setCoHolderFirstNameAction = payload => ({
  type: Types.SET_CO_HOLDER_INPUT,
  payload,
  meta: { name: 'firstName' },
});

export const setCoHolderLastNameAction = payload => ({
  type: Types.SET_CO_HOLDER_INPUT,
  payload,
  meta: { name: 'lastName' },
});

export const setCoHolderPhoneNumberAction = payload => ({
  type: Types.SET_CO_HOLDER_INPUT,
  payload,
  meta: { name: 'phoneNumber' },
});

//////////////////////// VALIDATION ////////////////////////

export const validateCoHolderInputSuccessAction = res => ({
  type: Types.VALIDATE_CO_HOLDER_INPUT_SUCCESS,
  ...res,
});

export const validateCoHolderInputFailAction = err => ({
  type: Types.VALIDATE_CO_HOLDER_INPUT_FAIL,
  ...err,
});

export const validateCoHolderInputAction = ({ payload, name, validator, meta }) => {
  if (isNullOrEmpty(name) || isNullOrEmpty(validator))
    throw new Error('An object containing a name of input and a validator is required');

  return bind(
    {
      type: Types.VALIDATE_CO_HOLDER_INPUT,
      payload,
      meta: { ...meta, validator, name },
    },
    validateCoHolderInputSuccessAction,
    validateCoHolderInputFailAction,
  );
};

export const validateCoHolderFirstNameAction = payload =>
  validateCoHolderInputAction({
    payload,
    name: 'firstName',
    validator: value => {
      const options = {
        required: true,
        messages: {
          invalidValue: 'Fornavn mangler.',
          text: 'Udfyld venligst korrekt fornavn'
        },
      };

      return requiredValidator(value, options);
    },
  });

export const validateCoHolderLastNameAction = payload =>
  validateCoHolderInputAction({
    payload,
    name: 'lastName',
    validator: value => {
      const options = {
        required: true,
        messages: {
          invalidValue: 'Efternavn mangler.',
          text: 'Udfyld venligst korrekt efternavn'
        },
      };

      return requiredValidator(value, options);
    },
  });

export const validateCoHolderLegalNumberAction = (payload = '', options = {}) =>
  validateCoHolderInputAction({
    payload: legalNumberParser(payload, options),
    name: 'legalNumber',
    validator: legalNumberValidator,
    meta: {
      ...options,
      messages: {
        invalidValue: 'Ugyldig CPR-nummer.',
        valueMissing: 'CPR-nummer mangler.',
        text: 'Udfyld venligst korrekt CPR-nummer'
      }
    },
  });

export const phoneNumberIsValid = (value, opts = {}) =>
  opts.required || value !== '' ? phoneValidator.isValid(value, opts.country, opts.type) : true;

const getPhoneErrorReason = (value, type = null) => {
  if (value === '') return 'Mobilnummer mangler.';
  else if (type === null) return 'Ugyldig mobilnummer.';
};

export const validateCoHolderPhoneNumberAction = (payload = '', options = {}) =>
  validateCoHolderInputAction({
    payload,
    name: 'phoneNumber',
    meta: options,
    validator: (value, { legalUnit, type }) => {
      const valid = phoneNumberIsValid(value, {
        country: legalUnit,
        required: true,
        type: type,
      });

      const text = 'Udfyld venligst korrekt mobilnummer'

      return valid ? { value, type } : new Err(getPhoneErrorReason(value, type), text);
    },
  });

const validateActions = {
  firstName: validateCoHolderFirstNameAction,
  lastName: validateCoHolderLastNameAction,
  legalNumber: validateCoHolderLegalNumberAction,
  phoneNumber: validateCoHolderPhoneNumberAction,
};

export const getValidateActionForCoHolderInput = name => validateActions[name];
