import { Ok, Err } from '../types';
import { Maybe } from '../../maybe';

const LegalNumberPatterns = Maybe({
  commercial: [
    {
      legalUnit: 'DK',
      patterns: [/^\d{8}$/],
    },
  ],
  private: [
    {
      legalUnit: 'DK',
      patterns: [/^[0-9]{10}$/],
    },
  ],
});

export const legalNumberValidator = (value, meta) =>
  value
    ? LegalNumberPatterns.map(x => x[meta.businessArea.toLowerCase()])
      .map(x => x.find(y => y.legalUnit.toLowerCase() === meta.legalUnit.toLowerCase()))
      .map(x => x.patterns.some(x => x.test(value)))
      .map(x => (x ? new Ok(value) : new Err(meta.messages.invalidValue || 'invalidLegalNumber', meta.messages.text)))
      .valueOfOrElse(new Err(`No legal number validator registered for ${meta.businessArea}:${meta.legalUnit}`))
    : new Err(meta.messages.valueMissing || 'legalNumberMissing', meta.messages.text)
