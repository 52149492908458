<edi-guybrush-icon>

  <script>
    import camelCase from 'camelcase'
    import * as icons from '@online-sales/osux-guybrush-icon-wrapper'
    import './guybrush-icon.css'

    this.on('mount', () => {
      const iconName = camelCase(`${this.opts.iconName}-svg`)

      this.root.innerHTML = icons[iconName]
    })

  </script>

</edi-guybrush-icon>
