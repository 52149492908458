import { createSelector } from 'reselect';
import { getHasCoHolderState, getHasPolicyAtCompetitorState, getFillCoHolderLaterState } from './competitor-policy.selectors';
import { Err } from '../validation';

export const getCoHolderState = state => state.coHolder;

export const getCoHolderFirstNameState = createSelector(
  getCoHolderState,
  state => state.firstName,
);
export const getCoHolderLastNameState = createSelector(
  getCoHolderState,
  state => state.lastName,
);
export const getCoHolderLegalNumberState = createSelector(
  getCoHolderState,
  state => state.legalNumber,
);
export const getCoHolderPhoneNumberState = createSelector(
  getCoHolderState,
  state => state.phoneNumber,
);

export const getHasAnyCoHolderInputError = createSelector(
  getCoHolderFirstNameState,
  getCoHolderLastNameState,
  getCoHolderLegalNumberState,
  getCoHolderPhoneNumberState,
  (...inputs) => inputs.reduce((acc, curr) => acc || curr.error instanceof Err, false),
);

export const getAreCoHolderInputsValidated = createSelector(
  getCoHolderFirstNameState,
  getCoHolderLastNameState,
  getCoHolderLegalNumberState,
  getCoHolderPhoneNumberState,
  (...inputs) => inputs.reduce((acc, curr) => acc && curr.validated, true),
);

export const getAreCoHolderInputsValid = createSelector(
  getHasPolicyAtCompetitorState,
  getHasCoHolderState,
  getAreCoHolderInputsValidated,
  getHasAnyCoHolderInputError,
  getFillCoHolderLaterState,
  (hasOngoingInsuranceState, hasCoHolderState, areValidated, anyInputHasError, fillCoHolderLaterState) =>
    fillCoHolderLaterState.value === true ||
    (hasOngoingInsuranceState.value === true && hasCoHolderState.value === true
      ? areValidated && !anyInputHasError
      : true),
);

export const getNotValidatedCoHolderInputs = createSelector(
  getCoHolderState,
  state => {
    const keys = Object.keys(state);
    const values = Object.values(state);

    const keyValueInputs = [];

    keys.forEach((key, index) =>
      keyValueInputs.push({
        key,
        values: values[index],
      }),
    );

    return keyValueInputs.reduce((acc, curr) => (!curr.values.validated ? [...acc, curr.key] : acc), []);
  },
);

export const getValueForCoHolderInput = name =>
  createSelector(
    getCoHolderState,
    state => state[name].value,
  );
