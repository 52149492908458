const mapStore = store => select => select(store.getState());

const observeStore = (store, select, onChange) => {
  let currentState;

  function handleChange() {
    const nextState = select(store.getState());

    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);

  handleChange();

  return unsubscribe;
};

export const createStoreMixin = store => {
  const subscriptions = {};

  //Functions in returned object is accessible in each tag [e.g. this.subscribe(..)]
  return {
    init: function() {
      this.on('before-unmount', () => {
        if (subscriptions[this._riot_id]) {
          subscriptions[this._riot_id].map(x => x());
          delete subscriptions[this._riot_id];
        }
      });
    },
    subscribe: function(select, onchange) {
      const unsubscribe = observeStore(store, select, onchange);

      subscriptions[this._riot_id] = subscriptions[this._riot_id]
        ? [...subscriptions[this._riot_id], unsubscribe]
        : [unsubscribe];

      return unsubscribe;
    },
    select: fn => mapStore(store)(fn),
    dispatch: store.dispatch,
  };
};
