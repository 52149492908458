export const toDateFromLocalString = (dateString, format) => {
  const getIsoDateString = () => {
    try {
      switch (format) {
        case 'DD-MM-YYYY': {
          return `${dateString.split('-')[0]}-${dateString.split('-')[1]}-${dateString.split('-')[2]}`
        }
        case 'DD.MM.YYYY': {
          return `${dateString.split('.')[0]}-${dateString.split('.')[1]}-${dateString.split('.')[2]}`
        }
        case 'YYMMDD': {
          return `${dateString.substring(0, 2)}-${dateString.split(2, 4)}-${dateString.substring(4, 6)}`
        }
        default:
        case 'YYYY-MM-DD': {
          return dateString
        }
      }
    } catch (error) {
      return null
    }
  }

  const date = new Date(getIsoDateString())

  const validDate = date instanceof Date && !isNaN(date)

  if (!validDate) {
    return null
  }

  return date
}

export const toCustomDateString = (inDate, format) => {
  let date
  if (inDate instanceof Date) {
    date = inDate
  } else {
    date = new Date(inDate)
  }

  const YYYY = date.getFullYear()
  const MM = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const DD = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()

  return format
    .replace('YYYY', YYYY)
    .replace('MM', MM)
    .replace('DD', DD)
}
