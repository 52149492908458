import riot from 'riot'

export class EdiForm extends HTMLElement {

  constructor() {
    super()

    this.tag = null
  }

  connectedCallback() {
    this.tag = riot.mount('edi-form')[0]
  }

  disconnectedCallback() {
    this.tag.unmount()
  }

}
