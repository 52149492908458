export const legalNumberParser = (value, { legalUnit, businessArea }) => {
  value = value.replace(/\s/g, '');

  if (legalUnit.toLowerCase() !== 'se' || businessArea.toLowerCase() === 'commercial') {
    return value;
  }

  const shouldNotParsePattern = /^(19|20)((\d{6})(-+)(\d*)|(\d){0,9})$/g;

  if (value.length === 0 || value.length < 10 || shouldNotParsePattern.test(value)) {
    return value;
  }

  if (value.length < 12) {
    const fullYear = new Date().getFullYear();
    const year = parseInt(value.substr(0, 2), 10);
    const currentCentury = Math.floor(fullYear / 100);
    const currentYear = fullYear % 100;

    if (year >= currentYear) {
      value = currentCentury - 1 + value;
    } else {
      value = currentCentury + value;
    }
  }

  if (value.length === 12) {
    return `${value.substr(0, 8)}-${value.substr(8, 4)}`;
  }

  return value;
};
