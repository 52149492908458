<edi-start-date>

  <edi-unified-noval-text-input
    ref='inputTag'
    handle-change={ handleChange }
    model={ startDateState } />

  <style>
    :root {
      display: block;
      height: 131px;
    }
  </style>

  <script>
    import tooltipComp from '@online-sales/osux-tooltip'
    import onlineSalesDatePicker from '@online-sales/osux-datepicker'
    import { setStartDateAction } from '../../common/actions'
    import { getStartDateState } from '../../common/selectors'
    import { toCustomDateString } from './date-format'
    import { getLocalization } from './localization'
    import {
      getDefaultStartDate,
      getMinimumStartDate,
      getEndDate,
    } from './start-date.util'

    const dispatchSelectedStartDate = date => this.dispatch(setStartDateAction(date));

    const overridePikaDayTransformStyle = () => {
      const css = 'div.pika-single { transform: scale(1); margin-top: 0; margin-left: 0; }';
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.type = 'text/css';

      head.appendChild(style);

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

    }

    const drawDatePicker = element => {

      onlineSalesDatePicker((element), {
        isoDefaultDate: this.isoDefaultDate,
        isoMinDate: getMinimumStartDate(),
        isoMaxDate: getEndDate(),
        setInputIconClass: true,
        languageCode: 'da-DK',
        disableBoundCheckOnManualInput: true,
        onDateSelected: isoDateString => {
          dispatchSelectedStartDate(isoDateString)
        }
      })

    }

    this.on('before-mount', () => {
      this.isoDefaultDate = getDefaultStartDate()
      this.dispatch(setStartDateAction(this.isoDefaultDate));

      this.subscribe(getStartDateState, startDateState => {
        this.update({
          startDateState: {
            ...startDateState,
            value: toCustomDateString(startDateState.value, getLocalization('da-DK').yearFormat),
            error: null, /* Disable the error bubble, it dont work together with the date picker. */
          }
        });

      });
    });

    this.on('mount', () => {
      const inputElement = this.root.querySelector('input')
      const { inputTag } = this.refs

      // To show initial value we need to wait for
      // edi-unified-noval-text-input to mount
      inputTag.on('mount', () => {
        tooltipComp(this.root)
        drawDatePicker(inputElement)
      })

      overridePikaDayTransformStyle() //Temp
    });

  </script>
</edi-start-date>
