import riot from 'riot';

import { combineReducers } from 'redux';
import { configureStore } from './store';
import { authService } from './authentication';
import { createStoreMixin } from './mixins';

import { EdiForm } from './custom-elements'

export const init = (preLoadedState = {}, reducers) => {

  const store = configureStore(preLoadedState, combineReducers({ ...reducers }));

  const storeMixIn = createStoreMixin(store);

  riot.mixin({
    ...storeMixIn,
    isAuthenticated: authService.isAuthenticated,
  });

  window.customElements.define('edi-form', EdiForm);
}
