export class Ok {
  constructor(value) {
    this.value = value;
  }
}

export class Err extends Error {
  constructor(message, text = null) {
    super(message);
    this.message = message;
    this.text = text
  }
}
