export const swedishLocal = {
  yearFormat: 'YYYY-MM-DD',
  frontLinerFormat: 'YYMMDD',
  i18n: {
    previousMonth: 'Föregående månad',
    nextMonth: 'Nästa månad',
    months: [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December'
    ],
    weekdays: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
    weekdaysShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör']
  }
}
export const danishLocal = {
  yearFormat: 'DD-MM-YYYY',
  frontLinerFormat: 'DD-MM-YYYY',
  i18n: {
    previousMonth: '',
    nextMonth: '',
    months: 'Januar_Februar_Marts_April_Maj_Juni_Juli_August_September_Oktober_November_December'.split('_'),
    weekdays: 'Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag'.split('_'),
    weekdaysShort: 'Søn_Man_Tir_Ons_Tor_Fre_Lør'.split('_')
  }
}
export const norwegianLocal = {
  yearFormat: 'DD.MM.YYYY',
  frontLinerFormat: 'DD.MM.YYYY',
  i18n: {
    previousMonth: '',
    nextMonth: '',
    months: 'Januar_Februar_Mars_April_Mai_Juni_Juli_August_September_Oktober_November_Desember'.split('_'),
    weekdays: 'Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag'.split('_'),
    weekdaysShort: 'Søn_Man_Tir_Ons_Tor_Fre_Lør'.split('_')
  }
}
export const finishLocal = {
  yearFormat: 'DD.MM.YYYY',
  frontLinerFormat: 'DD.MM.YYYY',
  i18n: {
    previousMonth: '',
    nextMonth: '',
    months: 'Tammikuu_Helmikuu_Maaliskuu_Huhtikuu_Toukokuu_Kesäkuu_Heinäkuu_Elokuu_Syyskuu_Lokakuu_Marraskuu_Joulukuu'.split(
      '_'
    ),
    weekdays: 'Sunnuntai_Maanantai_Tiistai_Keskiviikko_Torstai_Perjantai_Lauantai'.split('_'),
    weekdaysShort: 'Su_Ma_Ti_Ke_To_Pe_La'.split('_')
  }
}

export const getLocalization = languageCode => {
  const langString = languageCode ? languageCode.toLowerCase().replace('_', '-') : undefined
  switch (langString) {
    case 'dk':
    case 'da':
    case 'da-dk':
      return danishLocal
    case 'fi':
    case 'fi-fi':
      return finishLocal
    case 'nb':
    case 'nn':
    case 'no':
    case 'nb-no':
    case 'nn-no':
      return norwegianLocal
    default:
    case 'sv':
    case 'se':
    case 'sv-se':
      return swedishLocal
  }
}
