import { Types } from '../actions';
import { initialState } from '../state/competitor-policy.state';
import { cancellationDateShorterNoticePeriod } from '../constants/cancellation-date-codes';

const mapOptions = options =>
  options.map(o => ({
    name: o.name,
    value: o.id,
  }));

export const competitorPolicy = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_HAS_POLICY_AT_COMPETITOR:
      return {
        ...state,
        hasPolicyAtCompetitor: {
          ...state.hasPolicyAtCompetitor,
          value: action.payload,
        },
      };
    case Types.SET_COMPETITOR:
      return {
        ...state,
        competitor: {
          ...state.competitor,
          value: action.payload,
        },
      };
    case Types.SET_ENDING_MONTH:
      return {
        ...state,
        endingMonth: {
          ...state.endingMonth,
          value: action.payload,
        },
      };
    case Types.SET_CANCEL_AS_SOON_AS_POSSIBLE_MONTH:
      return {
        ...state,
        cancelAsSoonAsPossibleMonth: {
          ...state.cancelAsSoonAsPossibleMonth,
          value: action.payload
        },
      };
    case Types.SET_HAS_CO_HOLDER:
      return {
        ...state,
        hasCoHolder: {
          ...state.hasCoHolder,
          value: action.payload,
        },
      };
    case Types.SET_POLICY_NUMBER:
      return {
        ...state,
        policyNumber: {
          ...state.policyNumber,
          value: action.payload,
        },
      };
    case Types.SET_FILL_CO_HOLDER_LATER:
      return {
        ...state,
        fillCoHolderLater: {
          ...state.fillCoHolderLater,
          value: action.payload,
        },
      };
    case Types.FETCH_INITIALIZE_SUCCESS:
      const firstCancelAsSoonAsPossibleMonth = new Date(action.payload.cancelAsSoonAsPossibleDate).getMonth() || 0;
      const ediData = action.payload.ediData || {};
      const showPolicyNumber = action.payload.showPolicyNumberOption;
      const policyNumber = showPolicyNumber ? { ...state.policyNumber, hidden: false } : { ...state.policyNumber, hidden: true };
      return {
        ...state,
        competitor: {
          ...state.competitor,
          options: mapOptions(action.payload.competitors),
          value: ediData.competitorId || action.payload.competitors[0].id,
        },
        hasPolicyAtCompetitor: {
          ...state.hasPolicyAtCompetitor,
          value: ediData.cancelExistingInsurance === true,
        },
        hasCoHolder: {
          ...state.hasCoHolder,
          value: ediData.hasCoHolder === true,
        },
        endingMonth: {
          ...state.endingMonth,
          value: ediData.cancelMonth || '0',
        },
        cancelAsSoonAsPossibleMonth: {
          ...state.cancelAsSoonAsPossibleMonth,
          value: ediData.cancelMonth || firstCancelAsSoonAsPossibleMonth || '0',
        },
        policyNumber,
        firstCancelAsSoonAsPossibleMonth,
      };
    case Types.FETCH_INITIALIZE_FAIL:
      return {
        ...state,
        competitor: {
          ...state.competitor,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
