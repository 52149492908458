import { bind } from 'redux-effects';
import { fetch } from '../effects/redux-effects-fetch';

export const createHeaders = () =>
  new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

export const createPostRequest = ({ url, body }) =>
  new Request(url, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(body),
  });

export const createGetRequest = ({ url }) =>
  new Request(url, {
    method: 'GET',
    headers: createHeaders(),
  });

export const postAction = (payload, success, fail) => bind(fetch(createPostRequest(payload)), success, fail);

export const getAction = (payload, success, fail) => bind(fetch(createGetRequest(payload)), success, fail);
