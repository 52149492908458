import { bind } from 'redux-effects';
import { booleanValidator } from '../validation';
import { postAction } from './fetch.actions';

export const Types = {
  SET_STARTING_WISH: '[EDI] SET_STARTING_WISH',
  SET_START_DATE: '[EDI] SET_START_DATE',
  VALIDATE_START_DATE: '[EDI] VALIDATE_START_DATE',
  VALIDATE_START_DATE_SUCCESS: '[EDI] VALIDATE_START_DATE_SUCCESS',
  VALIDATE_START_DATE_FAIL: '[EDI] VALIDATE_START_DATE_FAIL',
  SET_STAGING_SESSION: '[EDI] SET_STAGING_SESSION',
  SET_IS_BUSY: '[EDI] SET_IS_BUSY',
  POST_FORM_DATA_SUCCESS: '[EDI] POST_FORM_DATA_SUCCESS',
  POST_FORM_DATA_FAIL: '[EDI] POST_FORM_DATA_FAIL',
  FETCH_INITIALIZE_SUCCESS: '[EDI] FETCH_INITIALIZE_SUCCESS',
  FETCH_INITIALIZE_FAIL: '[EDI] FETCH_INITIALIZE_FAIL',
};

export const fetchInitializeSuccessAction = res => ({
  type: Types.FETCH_INITIALIZE_SUCCESS,
  payload: res.value,
});

export const fetchInitializeFailAction = err => ({
  type: Types.FETCH_INITIALIZE_FAIL,
  ...err,
});

export const setStartingWishAction = payload => ({
  type: Types.SET_STARTING_WISH,
  payload,
});

export const setStartDateAction = payload => ({
  type: Types.SET_START_DATE,
  payload,
});

export const validateStartDateSuccessAction = res => ({
  type: Types.VALIDATE_START_DATE_SUCCESS,
  ...res,
});

export const validateStartDateFailAction = err => ({
  type: Types.VALIDATE_START_DATE_FAIL,
  ...err,
});

export const validateStartDateAction = payload =>
  bind(
    {
      type: Types.VALIDATE_START_DATE,
      payload,
      meta: {
        validator: value => {
          const options = { messages: { invalidValue: 'invalidValue' } };

          return booleanValidator(value, options);
        },
      },
    },
    validateStartDateSuccessAction,
    validateStartDateFailAction,
  );

export const setStagingSessionIdAction = (sessionId, sessionIndex) => ({
  type: Types.SET_STAGING_SESSION,
  payload: {
    sessionId,
    sessionIndex,
  },
});

export const postFormDataSuccess = res => ({
  type: Types.POST_FORM_DATA_SUCCESS,
  payload: res,
});

export const postFormDataFail = err => ({
  type: Types.POST_FORM_DATA_FAIL,
  payload: err,
});

export const postFormDataAction = payload =>
  bind({ type: Types.SET_IS_BUSY }, () => postAction(payload, postFormDataSuccess, postFormDataFail));
