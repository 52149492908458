export const initialState = {
  firstName: {
    validationType: 'text',
    title: 'Fornavn',
    infoText: 'Fornavn',
    value: '',
    error: null,
    validated: false,
    required: true
  },
  lastName: {
    validationType: 'text',
    title: 'Efternavn',
    infoText: 'Efternavn',
    id: 'lastName',
    value: '',
    error: null,
    validated: false,
    required: true
  },
  legalNumber: {
    validationType: 'number',
    title: 'CPR-nummer',
    infoText: 'CPR-nummer',
    value: '',
    error: null,
    validated: false,
    required: true
  },
  phoneNumber: {
    validationType: 'phone',
    title: 'Mobilnummer',
    infoText: 'Mobilnummer',
    countryCode: 'DK',
    value: '',
    error: null,
    validated: false,
    required: true
  },
};
