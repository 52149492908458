export const INTERNATIONAL_PREFIX = ['+', '00'];

export const types = {
  FIXED_LINE: 'FIXED_LINE',
  MOBILE: 'MOBILE',
  UAN: 'UAN',
  FIXED_LINE_OR_MOBILE: 'FIXED_LINE_OR_MOBILE',
};

//More to be added if necessary..
export const territories = [
  {
    countryId: 'SE',
    countryName: 'Sweden',
    countryCode: 46,
    nationalPrefix: /^0/,
    patterns: [
      {
        pattern: /^1(?:0[1-8]\d{6}|[136]\d{5,7}|(?:2[0-35]|4[0-4]|5[0-25-9]|7[13-6]|[89]\d)\d{5,6})$|^2(?:[136]\d{5,7}|(?:2[0-7]|4[0136-8]|5[0138]|7[018]|8[01]|9[0-57])\d{5,6})$|^3(?:[356]\d{5,7}|(?:0[0-4]|1\d|2[0-25]|4[056]|7[0-2]|8[0-3]|9[023])\d{5,6})$|^4(?:[0246]\d{5,7}|(?:1[013-8]|3[0135]|5[14-79]|7[0-246-9]|8[0156]|9[0-689])\d{5,6})$|^5(?:0[0-6]|[15][0-5]|2[0-68]|3[0-4]|4\d|6[03-5]|7[013]|8[0-79]|9[01])\d{5,6}|6(?:[03]\d{5,7}|(?:1[1-3]|2[0-4]|4[02-57]|5[0-37]|6[0-3]|7[0-2]|8[0247]|9[0-356])\d{5,6})$|^8\d{6,8}|9(?:0[1-9]\d{4,6}|^(?:1[0-68]|2\d|3[02-5]|4[0-3]|5[0-4]|[68][01]|7[0135-8])\d{5,6})$/g,
        type: types.FIXED_LINE,
        maxLength: 9,
        minLength: 7
      },
      {
        pattern: /^7[02369]\d{7}$/g,
        type: types.MOBILE,
        length: 9
      }
    ],
    formatting: [
      {
        pattern: /^(7\d)(\d{3})(\d{2})(\d{2})$/,
        leadingDigits: /^7/,
        national: '0$1-$2 $3 $4',
        international: '+46 $1 $2 $3 $4',
        e164: '+46$1$2$3$4'
      },
      {
        pattern: /^(8)(\d{2,3})(\d{2,3})(\d{2})$/,
        leadingDigits: /^8/,
        national: '0$1-$2 $3 $4',
        international: '+46 $1 $2 $3 $4',
        e164: '+46$1$2$3$4'
      },
      {
        pattern: /^([1-69]\d)(\d{2,3})(\d{2})(\d{2})$/,
        leadingDigits: /^1[013689]|^2[0136]|^3[1356]|^4[0246]|^54|^6[03]|^90/,
        national: '0$1-$2 $3 $4',
        international: '+46 $1 $2 $3 $4',
        e164: '+46$1$2$3$4'
      },
      {
        pattern: /^([1-469]\d)(\d{3})(\d{2})$/,
        leadingDigits: /^[12][136]|^3[356]|^4[0246]|^6[03]|^90/,
        national: '0$1-$2 $3',
        international: '+46 $1 $2 $3',
        e164: '+46$1$2$3'
      },
      {
        pattern: /^(\d{3})(\d{2})(\d{2})(\d{2})$/,
        leadingDigits: /^1[2457]|^2(?:[247-9]|5[0138])|^3[0247-9]|^4[1357-9]|^5[0-35-9]|^6(?:[124-689]|7[0-2])|^9(?:[125-8]|3[0-5]|4[0-3])/,
        national: '0$1-$2 $3 $4',
        international: '+46 $1 $2 $3 $4',
        e164: '+46$1$2$3$4'
      },
      {
        pattern: /^(\d{3})(\d{2,3})(\d{2})$/,
        leadingDigits: /^1[2457]|^2(?:[247-9]|5[0138])|^3[0247-9]|^4[1357-9]|^5[0-35-9]|^6(?:[124-689]|7[0-2])|^9(?:[125-8]|3[0-5]|4[0-3])/,
        national: '0$1-$2 $3',
        international: '+46 $1 $2 $3',
        e164: '+46$1$2$3'
      }
    ]
  },
  {
    countryId: 'NO',
    countryName: 'Norway',
    countryCode: 47,
    patterns: [
      {
        pattern: /^(?:2[1-4]|3[1-3578]|5[1-35-7]|6[1-4679]|7[0-8])\d{6}$/g,
        type: types.FIXED_LINE,
        length: 8
      },
      {
        pattern: /^(?:4[015-8]|5[89]|87|9\d)\d{6}$/g,
        type: types.MOBILE,
        length: 8
      },
      {
        pattern: /^0\d{4}$|^81(?:0(?:0[7-9]|1\d)|5\d{2})\d{3}$/g,
        type: types.UAN,
        minLength: 5,
        maxLength: 8
      }
    ],
    formatting: [
      {
        pattern: /^([489]\d{2})(\d{2})(\d{3})$/,
        leadingDigits: /^[489]/,
        national: '$1 $2 $3',
        international: '+47 $1 $2 $3',
        e164: '+47$1$2$3'
      },
      {
        pattern: /^([235-7]\d)(\d{2})(\d{2})(\d{2})$/,
        leadingDigits: /^[235-7]/,
        national: '$1 $2 $3 $4',
        international: '+47 $1 $2 $3 $4',
        e164: '+47$1$2$3$4'
      }
    ]
  },
  {
    countryId: 'FI',
    countryName: 'Finland',
    countryCode: 358,
    nationalPrefix: /^0/,
    patterns: [
      {
        pattern: /^1(?:[3569][1-8]\d{3,9}|[47]\d{5,10})$|^2[1-8]\d{3,9}$|^3(?:[1-8]\d{3,9}|9\d{4,8})$|[5689][1-8]\d{3,9}$/g,
        type: types.FIXED_LINE,
        minLength: 5,
        maxLength: 12
      },
      {
        pattern: /^4(?:[0-8]\d{4,9}|9\d{3,8})$|^50\d{4,8}$/g,
        type: types.MOBILE,
        minLength: 6,
        maxLength: 11
      },
      {
        pattern: /^[13]0\d{4,8}$|^2(?:0(?:[016-8]\d{3,7}|[2-59]\d{2,7})|9\d{4,8})$|^60(?:[12]\d{5,6}|6\d{7})$|^7(?:1\d{7}|3\d{8}|5[03-9]\d{3,7})$/g,
        type: types.UAN,
        minLength: 5,
        maxLength: 10
      }
    ],
    formatting: [
      {
        pattern: /^(\d{3})(\d{3,7})$/,
        leadingDigits: /^(?:[1-3]0|[6-8])0/,
        national: '0$1 $2',
        international: '+358$1$2',
        e164: '+358$1$2'
      },
      {
        pattern: /^(\d{2})(\d{4,10})$/,
        leadingDigits: /^[14]|^2[09]|^50|^7[135]/,
        national: '0$1 $2',
        international: '+358 $1 $2',
        e164: '+358$1$2'
      },
      {
        pattern: /^(\d{2})(\d{4,11})$/,
        leadingDigits: /^[25689][1-8]|^3/,
        national: '0$1 $2',
        international: '+358 $1 $2',
        e164: '+358$1$2'
      },
      {
        pattern: /^(116\d{3})$/,
        leadingDigits: /^116[12]/,
        national: '$1',
        international: '+358 $1',
        e164: '+358$1'
      },
      {
        pattern: /^(75\d{3})$/,
        leadingDigits: /^75[12]/,
        national: '$1',
        international: '+358 $1',
        e164: '+358$1'
      }
    ]
  },
  {
    countryId: 'DK',
    countryName: 'Denmark',
    countryCode: 45,
    patterns: [
      {
        pattern: /^(?:[2-7]\d|8[126-9]|9[1-36-9])\d{6}$/g,
        type: types.FIXED_LINE,
        length: 8
      },
      {
        pattern: /^(?:[2-7]\d|8[126-9]|9[1-36-9])\d{6}$/g,
        type: types.MOBILE,
        length: 8
      }
    ],
    formatting: [
      {
        pattern: /^(\d{2})(\d{2})(\d{2})(\d{2})$/,
        national: '$1 $2 $3 $4',
        international: '+45 $1 $2 $3 $4',
        e164: '+45$1$2$3$4'
      }
    ]
  },
  {
    countryId: 'LV',
    countryName: 'Latvia',
    countryCode: 371,
    patterns: [
      {
        pattern: /^(?:[2-7]\d|8[126-9]|9[1-36-9])\d{6}$/g,
        type: types.FIXED_LINE,
        length: 8,
      },
      {
        pattern: /^(?:[2-7]\d|8[126-9]|9[1-36-9])\d{6}$/g,
        type: types.MOBILE,
        length: 8,
      },
    ],
    formatting: [
      {
        pattern: /^(\d{2})(\d{2})(\d{2})(\d{2})$/,
        national: '$1 $2 $3 $4',
        international: '+371 $1 $2 $3 $4',
        e164: '+371$1$2$3$4',
      },
    ],
  },
];

export default territories;
