<edi-form>
  <h1 if="{ isComponentInMyPages }" class="myPagesTitle">Opsigelse af forikring i andet selskab</h1>

  <h4 class="osux-expand-title">Hvornår ønsker du forsikringen skal starte?</h4>
  <div class="osux-expand-content">
  <p if="{ !successful }">Udfyld oplysninger om hvornår din forsikring skal starte, og om du eventuelt har forsikring i et andet forsikringsselskab, som vi skal opsige for dig.</p>

  <div if="{ successful !== true && successful !== false }" style="text-align: center;">
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
  </div>

  <div if="{ successful === true }" style="text-align:center">
    <edi-guybrush-icon icon-name="check" />
    <h3 class="done-text">Udført</h3>
  </div>
  <div if="{ successful === false }">
    <edi-unified-boolean-radio
      model="{ hasCompetitorAtPolicyState }"
      handle-change="{ onHasPolicyAtCompetitorChanged }"
    />

    <edi-animated-form-container is-open="{ hasCompetitorAtPolicyState.value === false }">
      <edi-start-date />
    </edi-animated-form-container>

    <div if="{ hasCompetitorAtPolicyState.value === true }">
      <edi-unified-dropdown model="{ competitorState }" handle-change="{ onCompetitorChanged  }" />
      <div if="{ !policyNumberState.hidden }">
        <edi-unified-noval-text-input model="{ policyNumberState }" handle-change="{ onPolicyNumberChanged }" />
      </div>

      <edi-unified-dropdown model="{ startingWishState }" handle-change="{ onStartingWishChanged }" />

      <edi-animated-form-container is-open="{ startingWishState.value === cancellationDateByExpiry }">
        <edi-unified-dropdown model="{ parent.endingMonthState }" handle-change="{ parent.onEndingMonthChanged }" />
      </edi-animated-form-container>

      <edi-animated-form-container is-open="{ startingWishState.value === cancellationDateShorterNoticePeriod && isInternal === true }">
        <edi-unified-dropdown model="{ parent.cancelAsSoonAsPossibleMonthState }" handle-change="{ parent.onCancelAsSoonAsPossibleMonthChanged }" />
      </edi-animated-form-container>

      <div if="{ startingWishState.value === cancellationDateShorterNoticePeriod }">
        <p>
          Vær opmærksom på, at der kan opkræves et gebyr af det forsikringsselskab hvor du opsiger din forsikring, hvis forsikringen har været gældende i mindre end 1 år.
        </p>
      </div>
      <div if="{ startingWishState.value === cancellationDateByWithdrawal }">
        <p>
          Vær opmærksom på at forsikringen kun kan fortrydes indtil 14 dage efter startdato i andet selskab.
        </p>
      </div>
      <div if="{ startingWishState.value === noEdi }">
        <p>
          Vær opmærksom på at 'Send modbevis' ikke må vælges, hvis andet selskab allerede har udstedt en forsikring.
        </p>
      </div>

      <edi-first-possible-start-date />
      <div if="{ isCommercial === false }">
        <edi-unified-boolean-radio model="{ hasCoHolderState }" handle-change="{ onHasCoHolderChanged }" />
      </div>
    </div>

    <edi-animated-form-container
      is-open="{ hasCompetitorAtPolicyState.value === true && hasCoHolderState.value === true }"
    >
      <edi-fill-co-holder-later />
    </edi-animated-form-container>
    <edi-animated-form-container
      is-open="{ hasCompetitorAtPolicyState.value === true && hasCoHolderState.value === true && fillCoHolderLaterState.value === true }"
    >
      <p>Vi sender en mail som en påmindelse</p>
    </edi-animated-form-container>
    <edi-animated-form-container
      is-open="{ hasCompetitorAtPolicyState.value === true && hasCoHolderState.value === true && fillCoHolderLaterState.value === false }"
    >
      <edi-co-holder />
    </edi-animated-form-container>

    <edi-submit-button />
    </div>

    <script>
      import './main.css';
      import {
        setHasPolicyAtCompetitorAction,
        setCompetitorAction,
        setStartingWishAction,
        setEndingMonthAction,
        setCancelAsSoonAsPossibleMonthAction,
        setHasCoHolderAction,
        setPolicyNumberAction,
        fetchInitializeAction,
        setStagingSessionIdAction,
      } from '../common/actions';
      import {
        getHasPolicyAtCompetitorState,
        getHasCoHolderState,
        getCompetitorState,
        getStartingWishState,
        getFillCoHolderLaterState,
        getEndingMonthState,
        getCancelAsSoonAsPossibleMonthState,
        getPolicyNumberState,
        getIsCommercial,
        getIsInternal,
        getIsEdiProcessCompleted,
        getIsEdiProcessSuccesful,
      } from '../common/selectors';

      import { cancellationDateByExpiry, cancellationDateByWithdrawal, cancellationDateShorterNoticePeriod, noEdi } from '../common/constants/cancellation-date-codes';

      this.onHasPolicyAtCompetitorChanged = ({ value }) => {
        this.dispatch(setHasPolicyAtCompetitorAction(value));
      };

      this.onCompetitorChanged = ({ value }) => {
        this.dispatch(setCompetitorAction(value));
      };

      this.onStartingWishChanged = ({ value }) => {
        this.dispatch(setStartingWishAction(parseInt(value, 0)));
      };

      this.onEndingMonthChanged = ({ value }) => {
        this.dispatch(setEndingMonthAction(value));
      };

      this.onCancelAsSoonAsPossibleMonthChanged = ({ value }) => {
        this.dispatch(setCancelAsSoonAsPossibleMonthAction(value));
      }

      this.onHasCoHolderChanged = ({ value }) => {
        this.dispatch(setHasCoHolderAction(value));
      };
      
      this.onPolicyNumberChanged = ({ value }) => {
        this.dispatch(setPolicyNumberAction(value));
      };

      this.on('before-mount', () => {
        let sessionId;
        let sessionIndex;

        this.isComponentInMyPages = this.opts.context === 'mypages' || this.opts.context === 'mypages-internal';

        if (this.opts.stagingSessionId) {
          sessionId = this.opts.stagingSessionId;
          sessionIndex = this.opts.stagingSessionIndex || '0';
          this.dispatch(setStagingSessionIdAction(sessionId, sessionIndex));
        }

        this.update({ cancellationDateByExpiry, cancellationDateByWithdrawal, cancellationDateShorterNoticePeriod, noEdi });

        this.dispatch(fetchInitializeAction(sessionId, sessionIndex));

        this.subscribe(getHasPolicyAtCompetitorState, hasCompetitorAtPolicyState =>
          this.update({ hasCompetitorAtPolicyState }),
        );

        this.subscribe(getCompetitorState, competitorState => this.update({ competitorState }));

        this.subscribe(getPolicyNumberState, policyNumberState => this.update({ policyNumberState }));

        this.subscribe(getStartingWishState, startingWishState => this.update({ startingWishState }));

        this.subscribe(getFillCoHolderLaterState, fillCoHolderLaterState => this.update({ fillCoHolderLaterState }));

        this.subscribe(getEndingMonthState, endingMonthState => this.update({ endingMonthState }));

        this.subscribe(getCancelAsSoonAsPossibleMonthState, cancelAsSoonAsPossibleMonthState => this.update({ cancelAsSoonAsPossibleMonthState }));

        this.subscribe(getHasCoHolderState, hasCoHolderState => this.update({ hasCoHolderState }));

        this.subscribe(getIsCommercial, isCommercial => this.update({ isCommercial }));

        this.subscribe(getIsInternal, isInternal => this.update({ isInternal }))

        // Other subscriptions must be added above this line.
        this.subscribe(getIsEdiProcessSuccesful, successful => this.update({ successful }));

        this.subscribe(getIsEdiProcessCompleted, isCompleted => {
          if (isCompleted) {
            const isSuccessful = this.select(getIsEdiProcessSuccesful);
            const hasCompetitorAtPolicyState = this.select(getHasPolicyAtCompetitorState);
            const fillCoHolderLaterState = this.select(getFillCoHolderLaterState);

            this.root.dispatchEvent(
              new CustomEvent('edi-form:done', {
                detail: {
                  success: isSuccessful,
                  isCompanyChange: hasCompetitorAtPolicyState.value,
                  fillCoHolderLater: fillCoHolderLaterState.value,
                },
                bubbles: true,
              }),
            );
          }
        });
      });
    </script>
  </div>
</edi-form>
