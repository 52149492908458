import { Types } from '../actions';
import { initialState, privateDefaultOptions, privateOptionsInternal, privateOptionsInternalWithMotor, commercialDefaultOptions, commercialOptionsInternalWithMotor } from '../state/staged-policy.state';
import { cancellationDateByExpiry, cancellationDateShorterNoticePeriod } from '../constants/cancellation-date-codes';

export const stagedPolicy = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_STARTING_WISH:
      return {
        ...state,
        startingWish: {
          ...state.startingWish,
          value: action.payload,
        },
      };
    case Types.SET_START_DATE:
      return {
        ...state,
        startDate: {
          ...state.startDate,
          value: action.payload,
        },
      };
    case Types.SET_STAGING_SESSION:
      return {
        ...state,
        stagingSessionId: action.payload.sessionId,
        stagingSessionIndex: action.payload.sessionIndex,
      };
    case Types.VALIDATE_START_DATE_SUCCESS:
      return {
        ...state,
        startDate: {
          ...state.startDate,
          validated: true,
          error: null,
        },
      };
    case Types.VALIDATE_START_DATE_FAIL:
      return {
        ...state,
        startDate: {
          ...state.startDate,
          validated: true,
          error: action.payload,
        },
      };
    case Types.SET_IS_BUSY:
      return {
        ...state,
        isBusy: true,
      };
    case Types.POST_FORM_DATA_SUCCESS:
      return {
        ...state,
        isBusy: false,
        completed: true,
        successful: true,
      };
    case Types.POST_FORM_DATA_FAIL:
      return {
        ...state,
        isBusy: false,
        completed: true,
        successful: false,
      };
    case Types.FETCH_INITIALIZE_SUCCESS:
      const ediData = action.payload.ediData || {};

      let options = [];
      if (action.payload.isCommercial) {
        options = state.startingWish && state.startingWish.options ? state.startingWish.options : commercialDefaultOptions;
        if (action.payload.isInternal && action.payload.showMotorStartingWish) {
          options = commercialOptionsInternalWithMotor;
        }
      } else {
        options = state.startingWish && state.startingWish.options ? state.startingWish.options : privateDefaultOptions;
        if (action.payload.isInternal) {
          if (action.payload.showMotorStartingWish) {
            options = privateOptionsInternalWithMotor;
          } else {
            options = privateOptionsInternal;
          }
        }
      }

      const startDate = { ...state.startDate };
      if (ediData.startDate) startDate.value = ediData.startDate;

      return {
        ...state,
        completed: action.payload.ediDataReceived,
        successful: action.payload.ediDataReceived,
        startingWish: {
          ...state.startingWish,
          options: options,
          value: ediData.cancelOption || action.payload.isCommercial ? cancellationDateByExpiry : cancellationDateShorterNoticePeriod,
        },
        startDate,
        firstPossibleStartDateState: {
          ...state.firstPossibleStartDateState,
          cancelAsSoonAsPossibleDate: typeof action.payload.cancelAsSoonAsPossibleDate === 'undefined' ? null : new Date(action.payload.cancelAsSoonAsPossibleDate),
          cancelMonthFirstPossibleDate: typeof action.payload.cancelMonthFirstPossibleDate === 'undefined' ? null : new Date(action.payload.cancelMonthFirstPossibleDate),
        },
        isCommercial: action.payload.isCommercial || false,
        isInternal: action.payload.isInternal || false,
      };
    case Types.FETCH_INITIALIZE_FAIL:
      return {
        ...state,
        completed: false,
        successful: false,
      };
    default:
      return state;
  }
};
