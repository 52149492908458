import { createSelector } from 'reselect';
import { Err } from '../validation';

export const getCompetitorPolicyState = state => state.competitorPolicy;

export const getHasPolicyAtCompetitorState = createSelector(
  getCompetitorPolicyState,
  state => state.hasPolicyAtCompetitor,
);

export const getCompetitorState = createSelector(
  getCompetitorPolicyState,
  state => state.competitor,
);

export const getPolicyNumberState = createSelector(
  getCompetitorPolicyState,
  state => state.policyNumber
);

export const getEndingMonthState = createSelector(
  getCompetitorPolicyState,
  state => state.endingMonth,
);

export const getCancelAsSoonAsPossibleMonthState = createSelector(
  getCompetitorPolicyState,
  state => state.cancelAsSoonAsPossibleMonth,
)

export const getHasCoHolderState = createSelector(
  getCompetitorPolicyState,
  state => state.hasCoHolder,
);

export const getFillCoHolderLaterState = createSelector(
  getCompetitorPolicyState,
  state => state.fillCoHolderLater,
);

export const getNotValidatedCompetitorPolicyInputs = createSelector(
  getCompetitorPolicyState,
  state => {
    const keys = Object.keys(state);
    const values = Object.values(state);

    const keyValueInputs = [];

    keys.forEach((key, index) =>
      keyValueInputs.push({
        key,
        values: values[index],
      }),
    );

    return keyValueInputs.reduce((acc, curr) => (!curr.values.validated ? [...acc, curr.key] : acc), []);
  },
);

export const getStateForCompetitorPolicyInput = name =>
  createSelector(
    getCompetitorPolicyState,
    state => state[name],
  );

export const getHasAnyCompetitorPolicyInputError = createSelector(
  (...inputs) => inputs.reduce((acc, curr) => acc || curr.error instanceof Err, false),
);

//TODO: Combine with checking for required here?
export const getAreCompetitorPolicyInputsValidated = createSelector(
  (...inputs) => {
    return inputs.reduce((acc, curr) => acc && curr.validated, true)},
);

export const getAreCompetitorPolicyInputsValid = createSelector(
  getHasPolicyAtCompetitorState,
  getAreCompetitorPolicyInputsValidated,
  getHasAnyCompetitorPolicyInputError,
  (hasOnGoingInsuranceState, areValidated, anyInputHasError) =>
    hasOnGoingInsuranceState.value === false
      ? true
      : areValidated && !anyInputHasError,
);
