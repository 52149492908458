<edi-fill-co-holder-later>

  <edi-unified-checkbox
    overridden-styling
    model="{ fillCoHolderLaterState }"
    handle-change="{ onFillCoHolderLaterChanged }" />

  <style>
    :root {
      edi-unified-checkbox[overridden-styling] {
        label {
          font-family: If Sans,Arial,sans-serif;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: normal;
          font-variation-settings: "wght" 78;
          text-align: left;
          margin-bottom: 8px;
          text-indent: 30px;
        }

        [type="checkbox"] + label:before {
          top: 2px;
          width: 25px;
          height: 25px;
          left: -5px;
        }

        [type="checkbox"]:checked + label:after {
          top: 4px;
          width: 8px;
          height: 14px;
          left: 3px;
        }

        div.title {
          display: none;
        }

        .box {
          border: none;
          background: none;
          background-color: none;
        }
      }
    }
  </style>

  <script>
    import {
      setFillCoHolderLaterAction,
    } from '../../common/actions';
    import {
      getFillCoHolderLaterState,
    } from '../../common/selectors';

    this.onFillCoHolderLaterChanged = ({ value }) => {
      this.dispatch(setFillCoHolderLaterAction(value));
    };

    this.on('before-mount', () => {
      this.subscribe(getFillCoHolderLaterState, fillCoHolderLaterState => this.update({ fillCoHolderLaterState }));
    });
  </script>

</edi-fill-co-holder-later>

