// Opsigelsesdato pr. udløb
export const cancellationDateByExpiry = 285;
// Opsigelsesdato pga. skade
export const cancellationDateByInjury = 286;
// Opsigelse pr. anden dato
export const cancellationDateByDifferentDate = 288;
// Opsigelsesdato ved fritstillelse
export const cancellationDateByLayoff = 292;
// Opsigelse ved fortrydelse
export const cancellationDateByWithdrawal = 294;
// Forkortet opsigelsesvarsel
export const cancellationDateShorterNoticePeriod = 296;
// No EDI
export const noEdi = 1000;
