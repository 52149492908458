<edi-first-possible-start-date>
  <p>
    { this.firstPossibleStartDateState.text } <b>{ calculateFirstPossibleDate() }.</b>
  </p>

  <script>
    import {
      getStartingWishState,
      getEndingMonthState,
      getCancelAsSoonAsPossibleMonthState,
      getFirstPossibleStartDateState
    } from '../../common/selectors';
    import { cancellationDateByExpiry, cancellationDateShorterNoticePeriod } from '../../common/constants/cancellation-date-codes';

    this.calculateFirstPossibleDate = () => {
      if(this.startingWishState.value === cancellationDateShorterNoticePeriod && this.firstPossibleStartDateState.cancelAsSoonAsPossibleDate !== null) {
        return this.calculateFirstPossibleDateByMonth(this.firstPossibleStartDateState.cancelAsSoonAsPossibleDate, this.cancelAsSoonAsPossibleMonthState)
      }
      else if(this.startingWishState.value === cancellationDateByExpiry && this.firstPossibleStartDateState.cancelMonthFirstPossibleDate !== null) {
        return this.calculateFirstPossibleDateByMonth(this.firstPossibleStartDateState.cancelMonthFirstPossibleDate, this.endingMonthState)
      }
      return this.formatDate(new Date());
    }

    this.calculateFirstPossibleDateByMonth = (date, selectedMonth) => {
      var today = new Date();
      var firstPossibleDate = new Date(today.getFullYear(), selectedMonth.value, 1);
      if (firstPossibleDate < date) {
        firstPossibleDate = new Date(
          firstPossibleDate.getFullYear() + 1,
          firstPossibleDate.getMonth(),
          firstPossibleDate.getDate(),
        );
      }
      return this.formatDate(firstPossibleDate);
    };

    this.formatDate = date => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('da-DK', options);
    };

    this.on('before-mount', () => {
      this.subscribe(getStartingWishState, startingWishState => this.update({ startingWishState }));

      this.subscribe(getEndingMonthState, endingMonthState => this.update({ endingMonthState }));

      this.subscribe(getCancelAsSoonAsPossibleMonthState, cancelAsSoonAsPossibleMonthState => this.update({ cancelAsSoonAsPossibleMonthState }));

      this.subscribe(getFirstPossibleStartDateState, firstPossibleStartDateState =>
        this.update({ firstPossibleStartDateState }),
      );
    });
  </script>
</edi-first-possible-start-date>
