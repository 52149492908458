import decode from 'jwt-decode';

export const jwtToken = token => {
  const getTokenPayload = () => {
    try {
      return decode(token);
    } catch (e) {
      return {};
    }
  };

  return {
    getClaim: claimKey => getTokenPayload()[claimKey],
    hasClaim: claimKey => !!getTokenPayload()[claimKey],
  };
};
