import '@online-sales/osux-unified-boolean-radio';
import '@online-sales/osux-unified-dropdown';
import '@online-sales/osux-unified-noval-text-input';
import '@online-sales/osux-tooltip';
import '@online-sales/osux-unified-checkbox';

import '../common';
import './animated-form-container/animated-form-container.tag';
import './start-date/start-date.tag';
import './start-date/first-possible-start-date.tag';
import './co-holder/co-holder.tag';
import './co-holder/fill-co-holder-later.tag';
import './submit-button/submit-button.html';
import './main.html';

import * as reducers from '../common/reducers';

import { init } from '../common/app';

const preLoadedState = {};

init(preLoadedState, reducers);


