/* eslint consistent-this: off, no-undef: off, radix: off */

// TODO:
// Most of the dobounce/evaluativeDebounce usages could (should) be replaced
// with redux-debounced, but that will require that we re-evaluate the validationAction creator
// implementation because it is not open for extension.
// One way to go is to compose actions instead of calling "base functions".

export const debounce = (fn = () => void 0, waitFn = 500, immediate = false) => {
  let timeout;

  return (...args) => {
    const wait = typeof waitFn === 'function' ? waitFn() : waitFn;

    const context = this;

    const later = () => {
      timeout = null;

      if (!immediate) {
        fn.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      fn.apply(context, args);
    }
  };
};

export const evaluativeDebounce = (fn = () => void 0, wait = 500) => {
  let timeout;

  return (isImmediate = () => false, ...args) => {
    const context = this;
    const later = () => {
      timeout = null;

      if (!isImmediate()) {
        fn.apply(context, args);
      }
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (isImmediate()) {
      fn.apply(context, args);
    }
  };
};
