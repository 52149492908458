import { Err } from '../validation';
import omit from 'lodash/omit';

export const invokeValidator = action => {
  const validationResult = action.meta.validator(action.payload, action.meta);
  const meta = omit(action.meta, ['validator']);

  return validationResult instanceof Err
    ? Promise.reject({
        payload: validationResult,
        error: true,
        meta,
      })
    : Promise.resolve({
        payload: action.payload,
        error: false,
        meta,
      });
};

export const validationEffects = () => next => action =>
  action.meta && action.meta.validator ? invokeValidator(action) : next(action);
