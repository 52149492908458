<edi-submit-button>
  <spinner-button
    identifier="buttonSubmit"
    ref="submitButton"
    text="Indsend"
    disabled="{ isBusy || isDisabled }"
    is-busy="{ isBusy }"
    onclick="{ submit }"
  />

  <script>
    import { focusInvalidElement } from '../../common/utils';
    import { createStagingUrl } from '../../common/utils/url';
    import {
      getValidateActionForCoHolderInput,
      getValidateActionForCompetitorPolicyInput,
      postFormDataAction,
    } from '../../common/actions';
    import {
      getEdiRequestBody,
      getAreCoHolderInputsValid,
      getNotValidatedCoHolderInputs,
      getValueForCoHolderInput,
      getNotValidatedCompetitorPolicyInputs,
      getStateForCompetitorPolicyInput,
      getAreCompetitorPolicyInputsValid,
      getStagingSessionId,
      getStagingSessionIndex,
      getIsFormBusy,
      getIsEdiProcessCompleted,
      getIsEdiProcessSuccesful,
    } from '../../common/selectors';

    this.isBusy = false;
    this.isDisabled = false;

    this.on('before-mount', () => {
      this.subscribe(getIsFormBusy, isBusy => this.update({ isBusy }));
    });

    const validateNotValidatedCoHolderInputs = () => {
      const notValidatedCoHolderInputs = this.select(getNotValidatedCoHolderInputs);

      notValidatedCoHolderInputs.forEach(inputName => {
        const action = getValidateActionForCoHolderInput(inputName);
        const value = this.select(getValueForCoHolderInput(inputName));
        const options = { legalUnit: 'DK', businessArea: 'private', name: inputName };

        this.dispatch(action(value, options));
      });
    };

    const validateNotValidatedCompetitorPolicyInputs = () => {
      const notValidatedCompetitorPolicyInputs = this.select(getNotValidatedCompetitorPolicyInputs);

      notValidatedCompetitorPolicyInputs.forEach(inputName => {
        const action = getValidateActionForCompetitorPolicyInput(inputName);

        if (!action) return;

        const { value, required } = this.select(getStateForCompetitorPolicyInput(inputName));
        //TODO: Get options from state
        const options = {
          legalUnit: 'DK',
          businessArea: 'private',
          name: inputName,
          required,
        };

        this.dispatch(action(value, options));
      });
    };

    this.validateAll = () => {
      validateNotValidatedCompetitorPolicyInputs();
      validateNotValidatedCoHolderInputs();
    };

    this.canSubmit = () => {
      const areCompetitorPolicyInputsOk = this.select(getAreCompetitorPolicyInputsValid);
      const areCoHolderInputsOk = this.select(getAreCoHolderInputsValid);

      return areCompetitorPolicyInputsOk && areCoHolderInputsOk;
    };

    this.submit = () => {
      if (this.isDisabled) return;

      if (!this.canSubmit()) {
        this.validateAll();
        focusInvalidElement();

        return;
      }

      const stagingSessionId = this.select(getStagingSessionId);
      const stagingSessionIndex = this.select(getStagingSessionIndex);

      const url = createStagingUrl(stagingSessionId, stagingSessionIndex);
      const body = this.select(getEdiRequestBody);

      this.dispatch(postFormDataAction({ url, body }));
    };
  </script>
</edi-submit-button>
