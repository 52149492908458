import { getAction } from './fetch.actions';
import { EDI_API_URL } from '../config';

export const Types = {
  SET_HAS_POLICY_AT_COMPETITOR: '[EDI] SET_HAS_POLICY_AT_COMPETITOR',
  SET_COMPETITOR: '[EDI] SET_COMPETITOR',
  SET_ENDING_MONTH: '[EDI] SET_ENDING_MONTH',
  SET_CANCEL_AS_SOON_AS_POSSIBLE_MONTH: '[EDI] SET_CANCEL_AS_SOON_AS_POSSIBLE_MONTH',
  SET_HAS_CO_HOLDER: '[EDI] SET_HAS_CO_HOLDER',
  SET_FILL_CO_HOLDER_LATER: '[EDI] SET_FILL_CO_HOLDER_LATER',
  SET_POLICY_NUMBER: '[EDI] SET_POLICY_NUMBER',
  FETCH_INITIALIZE_SUCCESS: '[EDI] FETCH_INITIALIZE_SUCCESS',
  FETCH_INITIALIZE_FAIL: '[EDI] FETCH_INITIALIZE_FAIL',
};

export const setHasPolicyAtCompetitorAction = payload => ({
  type: Types.SET_HAS_POLICY_AT_COMPETITOR,
  payload,
});

export const setCompetitorAction = payload => ({
  type: Types.SET_COMPETITOR,
  payload,
});

export const setEndingMonthAction = payload => ({
  type: Types.SET_ENDING_MONTH,
  payload,
});

export const setCancelAsSoonAsPossibleMonthAction = payload => ({
  type: Types.SET_CANCEL_AS_SOON_AS_POSSIBLE_MONTH,
  payload,
});

export const setHasCoHolderAction = payload => ({
  type: Types.SET_HAS_CO_HOLDER,
  payload,
});

export const setFillCoHolderLaterAction = payload => ({
  type: Types.SET_FILL_CO_HOLDER_LATER,
  payload,
});

export const setPolicyNumberAction = payload => ({
  type: Types.SET_POLICY_NUMBER,
  payload
});

const INITIALIZE_URL = `${EDI_API_URL}initialize`;

export const fetchInitializeSuccessAction = res => ({
  type: Types.FETCH_INITIALIZE_SUCCESS,
  payload: res.value,
});

export const fetchInitializeFailAction = err => ({
  type: Types.FETCH_INITIALIZE_FAIL,
  ...err,
});

export const fetchInitializeAction = (stagingId, stagingIndex) => {
  const checkTokenIsInternal = () => {
    const parseJwt = token => {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch (e) {
        return null;
      }
    };

    try {
      var accessToken = sessionStorage.getItem('wp_access_token');
      if (accessToken) {
        var jwtToken = parseJwt(accessToken);
        if (jwtToken && jwtToken.idp && jwtToken.idp.toLowerCase() === 'europe') {
          return true;
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  let url = `${INITIALIZE_URL}?activationId=${stagingId}&stagingSessionId=${stagingIndex}`;

  const isInternal = checkTokenIsInternal();

  if (isInternal) {
    url = `${url}&isInternal=true`;
  }

  return getAction({ url: url }, fetchInitializeSuccessAction, fetchInitializeFailAction);
};

export const getValidateActionForCompetitorPolicyInput = name => {
  switch (name) {
    default:
      return null;
  }
};
