export const initialState = {
  hasPolicyAtCompetitor: {
    validationType: 'boolean',
    title: 'Har du forsikring i et andet selskab vi skal opsige?',
    description: 'Hvis du ikke har haft denne type forsikring før - eller ikke ønsker at vi skal opsige din forsikring for dig, så skal du svare Nej.',
    value: false,
  },
  competitor: {
    title: 'Nuværende forsikringsselskab',
    description: 'Vælg det forsikringsselskab fra listen, hvor du i dag har denne forsikring.',
    value: '',
    options: [],
  },
  endingMonth: {
    title: 'Hvilken måned udløber din forsikring?',
    value: '0',
    options: [
      {
        value: 0,
        name: 'Januar',
      },
      {
        value: 1,
        name: 'Feburar',
      },
      {
        value: 2,
        name: 'Marts',
      },
      {
        value: 3,
        name: 'April',
      },
      {
        value: 4,
        name: 'Maj',
      },
      {
        value: 5,
        name: 'Juni',
      },
      {
        value: 6,
        name: 'Juli',
      },
      {
        value: 7,
        name: 'August',
      },
      {
        value: 8,
        name: 'September',
      },
      {
        value: 9,
        name: 'Oktober',
      },
      {
        value: 10,
        name: 'November',
      },
      {
        value: 11,
        name: 'December',
      },
    ],
  },
  cancelAsSoonAsPossibleMonth:{
    title: 'Hvilken måned ønsker du forsikkringen skal starte?',
    value: '0',
    options: [
      {
        value: 0,
        name: 'Januar',
      },
      {
        value: 1,
        name: 'Feburar',
      },
      {
        value: 2,
        name: 'Marts',
      },
      {
        value: 3,
        name: 'April',
      },
      {
        value: 4,
        name: 'Maj',
      },
      {
        value: 5,
        name: 'Juni',
      },
      {
        value: 6,
        name: 'Juli',
      },
      {
        value: 7,
        name: 'August',
      },
      {
        value: 8,
        name: 'September',
      },
      {
        value: 9,
        name: 'Oktober',
      },
      {
        value: 10,
        name: 'November',
      },
      {
        value: 11,
        name: 'December',
      },
    ],
  },
  hasCoHolder: {
    validationType: 'boolean',
    title: 'Er der nogen anden på forsikringen?',
    description: 'Hvis der udover dig er registreret en person på den forsikring der skal opsiges, skal du svare Ja.',
    value: false,
    error: null,
    validated: false,
    required: true,
  },
  fillCoHolderLater: {
    validationType: 'boolean',
    name: '',
    text: 'Jeg udfylder dette senere på Mine sider',
    value: false,
    error: null,
    validated: false,
    required: true,
  },
  policyNumber: {
    validationType: 'text',
    title: 'Hvad er dit nuværende police nummer?',
    description: 'Har du mere end én forsikring af denne type i et andet forsikringsselskab, skal vi bruge dit nuværende policenummer, for at kunne opsige den pågældende forsikring.',
    id: 'policenummer',
    value: '',
    error: null,
    validated: true,
    required: false,
    hidden: false,
  },
};
