import { ifElse, identity, compose } from 'ramda';
import { cancellationDateByExpiry, cancellationDateShorterNoticePeriod } from '../constants/cancellation-date-codes';

type State = { __state: true; __fake: true };
type Body = {
  fillCoHolderLater: boolean;
  hasCoHolder: boolean;
  cancelExistingInsurance: boolean;
  startDate?: string;
  competitorId?: number;
  cancelOption: number;
  cancelMonth?: number;
  coHolderLegalNumber?: string;
  coHolderFirstName?: string;
  coHolderLastName?: string;
  coHolderPhoneNumber?: string;
};
type Condition = (state: State) => boolean;
type Apply = (state: State) => (body: Body) => Body;

const doNothing: Apply = () => identity;

const withStartDate: Apply = (state: any) => (body: Body) => ({
  ...body,
  startDate: state.stagedPolicy.startDate.value,
});

const wantsToStartNewPolicyPerExpiryOrShorterNoticePeriodAndIsInternal: Condition = (state: any) =>
  state.stagedPolicy.startingWish.value === cancellationDateByExpiry ||
  state.stagedPolicy.startingWish.value === cancellationDateShorterNoticePeriod && state.stagedPolicy.isInternal === true;

const withCancelMonth: Apply = (state: any) => (body: Body) => {
  var cancelMonth = 0;
  if (state.stagedPolicy.startingWish.value === cancellationDateByExpiry) {
    cancelMonth = parseInt(state.competitorPolicy.endingMonth.value, 10) + 1;
  } else if (state.stagedPolicy.startingWish.value === cancellationDateShorterNoticePeriod) {
    if (state.stagedPolicy.isInternal === false) {
      cancelMonth = state.competitorPolicy.firstCancelAsSoonAsPossibleMonth + 1 || 0;
    } else {
      cancelMonth = parseInt(state.competitorPolicy.cancelAsSoonAsPossibleMonth.value, 10) + 1;
    }
  }
  return {
    ...body,
    cancelMonth,
  };
};

const hasCoHolder: Condition = (state: any) => state.competitorPolicy.hasCoHolder.value === true;
const withCoHolder: Apply = (state: any) => (body: Body) => ({
  ...body,
  coHolderLegalNumber: state.coHolder.legalNumber.value,
  coHolderFirstName: state.coHolder.firstName.value,
  coHolderLastName: state.coHolder.lastName.value,
  coHolderPhoneNumber: state.coHolder.phoneNumber.value,
});

const withCompetitorInfo: Apply = (state: any) => (body: Body) => ({
  ...body,
  competitorId: state.competitorPolicy.competitor.value,
});

const withPolicyNumber: Apply = (state: any) => (body: Body) => {
  if (!(state.competitorPolicy && state.competitorPolicy.policyNumber)) return body;

  return {
    ...body,
    policyNumber: state.competitorPolicy.policyNumber.value,
  };
};

const hasPolicyAtCompetitor: Condition = (state: any) => state.competitorPolicy.hasPolicyAtCompetitor.value === true;
const withCancelInfo: Apply = (state: any) =>
  compose(
    withCompetitorInfo(state),
    withPolicyNumber(state),
    ifElse(hasCoHolder, withCoHolder, doNothing)(state),
    ifElse(wantsToStartNewPolicyPerExpiryOrShorterNoticePeriodAndIsInternal, withCancelMonth, doNothing)(state),
  );

const withData: Apply = ifElse(hasPolicyAtCompetitor, withCancelInfo, withStartDate);

export const getEdiRequestBody = (state: any) => {
  const { competitorPolicy, coHolder, stagedPolicy } = state;
  const body = withData(state)({
    cancelExistingInsurance: competitorPolicy.hasPolicyAtCompetitor.value,
    hasCoHolder: competitorPolicy.hasCoHolder.value,
    fillCoHolderLater: competitorPolicy.fillCoHolderLater.value && competitorPolicy.hasCoHolder.value,
    cancelOption: stagedPolicy.startingWish.value,
  });
  return body;
};
