import { Types as CoHolderTypes } from './co-holder.actions';
import { Types as CompetitorPolicyTypes } from './competitor-policy.actions';
import { Types as StagedPolicyTypes } from './staged-policy.actions';

export const Types = {
  ...CompetitorPolicyTypes,
  ...CoHolderTypes,
  ...StagedPolicyTypes,
};

export {
  setHasPolicyAtCompetitorAction,
  setCompetitorAction,
  setEndingMonthAction,
  setCancelAsSoonAsPossibleMonthAction,
  setHasCoHolderAction,
  setFillCoHolderLaterAction,
  getValidateActionForCompetitorPolicyInput,
  fetchInitializeAction,
  setPolicyNumberAction,
} from './competitor-policy.actions';

export {
  setCoHolderLegalNumberAction,
  validateCoHolderLegalNumberAction,
  setCoHolderFirstNameAction,
  validateCoHolderFirstNameAction,
  setCoHolderLastNameAction,
  validateCoHolderLastNameAction,
  setCoHolderPhoneNumberAction,
  validateCoHolderPhoneNumberAction,
  getValidateActionForCoHolderInput,
} from './co-holder.actions';

export {
  setStartingWishAction,
  setStartDateAction,
  setStagingSessionIdAction,
  postFormDataAction,
} from './staged-policy.actions';
