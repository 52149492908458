import { Ok, Err } from '../types';
import { isNullOrEmpty } from '../../utils';

export const requiredValidator = (value, options) => {
  if (!options) {
    return new Err('options must be defined');
  }

  return options.required && (isNullOrEmpty(value) || isNullOrEmpty(value.trim()))
    ? new Err(options.messages.invalidValue, options.messages.text)
    : new Ok(value);
};
